<template>
  <div class="container d-flex justify-content-center">
    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="form-header">
            <h2 class="form-title">:-) התבונן בטלפון שלך .. קיבלת קישור</h2>
          </div>

          <!-- <div class="d-flex justify-content-center input-code">
            <template v-for="(v, index) in values">
              <input
                :type="type === 'number' ? 'tel' : type"
                :pattern="type === 'number' ? '[0-9]' : null"
                :autoFocus="autoFocus && !loading && index === autoFocusIndex"
                :key="`${id}-${index}`"
                :data-id="index"
                :value="v"
                :ref="iRefs[index]"
                v-on:input="onValueChange"
                v-on:focus="onFocus"
                v-on:keydown="onKeyDown"
                :disabled="disable"
                :required="required"
                placeholder="0"
                maxlength="1"
              />
            </template>
          </div>

          <div class="mt-4 pr-3 text-right small-text" v-if="!loading">
            You didn't receive the code ?
            <span
              class="btn-link pointer"
              @click="resendCode()"
            >Resend code</span>
          </div> -->

        <div class="row" v-if="error" >
          <div class="col">
            <p class="text-error text-right pr-3 mt-2">{{error}}</p>
          </div>
        </div>

          <div class="d-flex justify-content-center" v-if="loading">
            <div class="spinner-border text-dark loader-phone link-spinner" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40
};
export default {
  name: "PhoneCodeComponent",

  data() {
    const { values } = this;
    const fields = 6;
    let vals;
    let autoFocusIndex = 0;
    if (values && values.length) {
      vals = [];
      for (let i = 0; i < fields; i++) {
        vals.push(values[i] || "");
      }
      autoFocusIndex = values.length >= fields ? 0 : values.length;
    } else {
      vals = Array(fields).fill("");
    }
    this.iRefs = [];
    for (let i = 0; i < fields; i++) {
      this.iRefs.push(`input_${i}`);
    }
    this.id = +new Date();
    return {
      values: vals,
      autoFocusIndex,
      
      autoFocus: true,
      
      required: false,
      type: "number",
      fields: fields
    };
  },
  mounted() {},
  computed: {
    loading() {
      return this.$store.getters.authLoading
    },
    disable() {
      return this.$store.getters.buttonDisable
    },

    error(){
         return this.$store.getters.error
    }

  },
  methods: {
    onFocus(e) {
      e.target.select(e);
    },
    async complete(val) {
      await this.$store.dispatch("check_code_phone");
    },
    change() {},
    resendCode() {},
    onValueChange(e) {
      const index = parseInt(e.target.dataset.id);
      const { type, fields } = this;
      if (type === "number") {
        e.target.value = e.target.value.replace(/[^\d]/gi, "");
      }
      if (
        e.target.value === "" ||
        (type === "number" && !e.target.validity.valid)
      ) {
        return;
      }
      let next;
      const value = e.target.value;
      let { values } = this;
      values = Object.assign([], values);
      if (value.length > 1) {
        let nextIndex = value.length + index - 1;
        if (nextIndex >= fields) {
          nextIndex = fields - 1;
        }
        next = this.iRefs[nextIndex];
        const split = value.split("");
        split.forEach((item, i) => {
          const cursor = index + i;
          if (cursor < fields) {
            values[cursor] = item;
          }
        });
        this.values = values;
      } else {
        next = this.iRefs[index + 1];
        values[index] = value;
        this.values = values;
      }
      if (next) {
        const element = this.$refs[next][0];
        element.focus();
        element.select();
      }
      this.triggerChange(values);
    },
    onKeyDown(e) {
      const index = parseInt(e.target.dataset.id);
      const prevIndex = index - 1;
      const nextIndex = index + 1;
      const prev = this.iRefs[prevIndex];
      const next = this.iRefs[nextIndex];
      switch (e.keyCode) {
        case KEY_CODE.backspace: {
          e.preventDefault();
          const vals = [...this.values];
          if (this.values[index]) {
            vals[index] = "";
            this.values = vals;
            this.triggerChange(vals);
          } else if (prev) {
            vals[prevIndex] = "";
            this.$refs[prev][0].focus();
            this.values = vals;
            this.triggerChange(vals);
          }
          break;
        }
        case KEY_CODE.left:
          e.preventDefault();
          if (prev) {
            this.$refs[prev][0].focus();
          }
          break;
        case KEY_CODE.right:
          e.preventDefault();
          if (next) {
            this.$refs[next][0].focus();
          }
          break;
        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault();
          break;
        default:
          // this.handleKeys[index] = true;
          break;
      }
    },
    triggerChange(values = this.values) {
      const fields = this.fields;
      const val = values.join("");
      if (val.length >= fields) {
        this.complete(val);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-code > input {
  border: solid 1px #ced4da;
  border-right: none;
  font-family: "Varela Round", sans-serif;
  font-size: 2rem;
  color: #000;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-appearance: initial;
  width: 4.375rem;
  height: 5.625rem;
}

.link-spinner{
    width: 3rem;
    height: 3rem;
}

.input-code > input::placeholder {
  color: #f6f6f6;
}

.pointer {
  cursor: pointer;
}

.small-text {
  font-size: 0.8rem;
}

.input-code > input:last-child {
  border-right: solid 1px #ced4da;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.input-code > input:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.input-code > input:focus {
  outline: none;
  border: 1px solid #000;
  caret-color: #000;
}
.input-code > input:focus + input {
  border-left: none;
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.5;
  filter: blur(0.5px);
  transition: opacity 0.3s;
}

.loader-phone {
  margin-top: 0.8rem;
}
</style>