<template>
  <div class="form-container form-container-login">
    <div class="form-header">
      <h2 class="form-title" v-text="'שחזור סיסמה'" />
    </div>

    <div class="form-under-header" v-text="'הזיני את מספר הטלפון שאיתו נרשמת ותקבלי הודעת SMS לשחזור הסיסמה'" />

    <div class="form-body">
      <form class="gong-form" @submit.prevent="checkData" autocomplete="off" method="post">
        <div class="form-group" v-if="success">
          <div class="alert alert-success" role="alert" v-text="success" />
        </div>

        <div class="form-group" v-else>
          <input
            type="text"
            placeholder="מספר טלפון או מייל"
            :class="['form-control', {'is-invalid': $v.email.$invalid && send}]"
            v-model.trim="email"
            @focus="deleteErrorServer"
          >
          <div class="invalid-feedback" v-if="$v.email.required === false" v-text="errors.required" />
        </div>

        <div class="error-server" v-if="error" v-text="error" />

        <div class="form-group">
          <a href="#" @click.prevent="toggleForgotPassword" class="forgotpassword-link" v-text="'חזרה להתחברות'" />
        </div>

        <div class="form-group" v-if="!success">
          <button type="submit" class="btn btn-primary btn-block" :disabled="disable">
            <div class="spinner-border text-light" role="status" v-if="loading">
              <span class="sr-only" v-text="'Loading...'" />
            </div>
            שלח
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ForgetPasswordComponent',

  data() {
    return {
      email: '',
      send: false
    }
  },

  computed: {
    errors() {
      return { required: 'שדה זה הינו חובה' }
    },
    loading() {
      return this.$store.getters.authLoadingForgot
    },
    disable() {
      return this.$store.getters.buttonDisableForgot
    },
    success() {
      return this.$store.getters.successForgot
    },
    error() {
      return this.$store.getters.errorForgot
    }
  },

  validations: {
    email: { required }
  },

  methods: {
    toggleForgotPassword() {
      this.$store.commit('clearSuccessForgetPassword', '')
      this.$emit('forgotPassword')
    },

    checkData() {
      this.send = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }
      this.sendNewPassword()
    },

    async sendNewPassword() {
      const form = new FormData()
      form.append('data', this.email)
      await this.$store.dispatch('sendNewPassword', { form })
    },

    deleteErrorServer() {
      this.$store.commit('clearErrorForgetPassword', '')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.forgotpassword-link {
  color: #000;
}
.form-under-header {
  text-align: center;
  margin-bottom: 1.5rem;
}
.alert-success {
  direction: rtl;
  text-align: right;
}
.error-server {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: right;
}
.form-control{
  direction: rtl;
}

@include media-breakpoint-down(sm) {
  .form-title {
    font-size: 1.2rem;
  }
  .form-container-login {
    padding: 0;
  }
  .form-container-login .form-group .btn {
    height: 3.4rem;
    font-size: 1rem;
  }
  .gong-form .form-group .form-control {
    height: 3.4rem;
    font-size: .8rem;
  }
  .gong-form .form-group {
    margin-bottom: 1.8rem;
  }
  #login .form-container .form-group .btn {
    height: 3.4rem;
    font-size: 1rem;
  }
  .form-header{
     margin-bottom: 0;
  }
  #login .form-container .form-group .btn {
    height: inherit;
    font-size: 1rem;
    line-height: 2;
  }
  #login .form-container .form-group .spinner-border {
    vertical-align: middle;
    margin-right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>
