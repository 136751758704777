<template>
  <div id="login">
    <section class="site-section">
      <div class="container">
        <div class="section-body">
          <div class="row" v-if="!linkPhoneStep">
            <div :class="['col-md-6', 'col-12', {'no-register-form': noRegister}]">
              <div v-if="fromCheckout && (device.desktop() || device.tablet())" class="form-container register-from-checkout">
                <div class="form-header">
                  <h2 class="form-title" v-text="'פעם ראשונה כאן? הירשמי והצטרפי'" />
                </div>

                <div class="form-body">
                  <router-link :to="{name: 'register'}" class="btn btn-primary btn-block" v-text="'הרשמה'" />
                  <p v-text="'או'" />
                  <router-link :to="{name: 'checkout', params: {isGuest: true}}" class="btn btn-outline-primary btn-block" v-text="'הזמנה כאורח/ת'" />
                </div>
              </div>
              <RegisterComponent v-else title="פתיחת חשבון חדש" :isRegister="true" />
            </div>

            <div :class="['col-md-6', 'col-12', {'no-register-form': !noRegister}]">
              <div class="form-container form-container-login" v-if="!forgotPassword" >
                <div class="form-header">
                  <h2 class="form-title" v-text="'כניסה לחשבונך'" />
                </div>
                <div class="form-body">
                  <form class="gong-form" @submit.prevent="checkData" autocomplete="off" method="post">
                    <div class="form-group" v-for="(input, index) of inputs" :key="index">
                      <input
                        :type="input.type"
                        :name="input.name"
                        @focus="deleteErrorServer"
                        :placeholder="input.placeholder"
                        :class="['form-control', {'is-invalid': ($v.form[input.name].$invalid && send)}]"
                        v-model.trim="form[input.name]"
                      >

                      <div class="invalid-feedback" v-if="$v.form[input.name].required === false" v-text="'שדה זה הינו חובה'" />
                      <div class="invalid-feedback" v-if="$v.form[input.name].email === false" v-text="'אנא הקלד כתובת דואר אלקטרוני תקינה'" />
                    </div>

                    <div class="form-group">
                      <a href="#" @click.prevent="toggleForgotPassword" class="forgotpassword-link" v-text="'שכחתי סיסמה'" />
                    </div>

                    <div class="error-server" v-if="error" v-text="error" />

                    <div class="form-group">
                      <button type="submit" class="btn btn-primary btn-block" :disabled="disable">
                        <div class="spinner-border text-light" role="status" v-if="loading">
                          <span class="sr-only" v-text="'Loading...'" />
                        </div>
                        התחברות
                      </button>
                    </div>
                  </form>

                  <div class="no-register">
                    <h4 v-text="'אין לך עדיין חשבון?'" />
                    <a href="#" :class="['btn', fromCheckout ? 'btn-primary' : 'btn-outline-primary']" @click.prevent="noRegister = !noRegister" v-text="'פתיחת חשבון חדש'" />
                    <p v-if="fromCheckout" v-text="'או'" />
                    <router-link v-if="fromCheckout" :to="{name: 'checkout', params: {isGuest: true}}" class="btn btn-outline-primary" v-text="'הזמנה כאורח/ת'" />
                  </div>
                </div>
              </div>
              <div v-if="forgotPassword" >
                <ForgetPasswordComponent @forgotPassword="toggleForgotPassword" />
              </div>
            </div>
          </div>

          <div class="row" v-if="linkPhoneStep">
            <div class="col-md-12 ">
              <PhoneCodeComponent />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import device from 'current-device'
import { eventEmitter } from '@/main'
import RegisterComponent from '@/components/auth/RegisterComponent' 
import PhoneCodeComponent from '@/components/auth/PhoneCodeComponent'
import ForgetPasswordComponent from '@/components/auth/ForgetPasswordComponent'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Login',

  components: {
    RegisterComponent, PhoneCodeComponent, ForgetPasswordComponent
  },

  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME + ' | כניסה לחשבונך | ' + process.env.VUE_APP_NAME_HEB
    }
  },

  props: {
    fromCheckout: { type: Boolean, default: false },
    favoritePage: { type: Boolean, default: false },
    fromForgetPassword: { type: Boolean, default: false }
  },

  data() {
    return {
      device,
      noRegister: this.$route.params.noRegister !== undefined ? this.$route.params.noRegister : true,
      form: {
        email: '',
        password: ''
      },
      send: false,
      forgotPassword: false,
      btnText: false
    }
  },

  created() {
    if(this.$store.getters.isLogin) {
      this.$router.push({ name: 'profile'})
    }

    eventEmitter.$on('changeRegForm', noRegister => this.noRegister = noRegister)
  },

  computed: {
    inputs() {
      return [
        { type: 'text', name: 'email', placeholder: 'מייל' },
        { type: 'password', name: 'password', placeholder: 'סיסמה' }
      ]
    },
    linkPhoneStep() {
      return this.$store.getters.codePhone
    }, 
    loading() {
      return this.$store.getters.authLoadingLogin
    },
    disable() {
      return this.$store.getters.buttonDisableLogin
    },
    error() {
      return this.$store.getters.errorLogin
    }
  },
  
  validations: {
    form: {
      email: { required, email },
      password: { required }
    },
  },

  methods:{
    checkData() {
      this.send = true
      this.$v.$touch()
      if(this.$v.$invalid) {
        return false
      }
      this.login()
    },

    deleteErrorServer() {
      if(this.error) {
        this.$store.commit('clearErrorLogin', '')
      }
    },

    async login() {
      const form = new FormData()
      form.append('username', this.form.email)
      form.append('password', this.form.password)

      await this.$store.dispatch('login', { form })

      if(this.error === '') {
        if(this.fromCheckout) {
          this.$router.push({ name: 'checkout' })
        } else if(this.favoritePage) {
          this.$router.push({ name: 'favorites' })
        } else if(this.fromForgetPassword) {
          this.$router.push({ name: 'home' })
        } else if(this.$route.params.favorite) {
          const productId = this.$route.params.favorite.productId
          await this.$store.dispatch('updateFavProduct', { productId })

          this.$router.push({
            name: 'product',
            params: {
              subCategoryId: this.$route.params.favorite.subCategoryId,
              productId: this.$route.params.favorite.productId,
            }
          })
        } else {
          this.$router.go(-1)
        }
      }
    },

    toggleForgotPassword() {
      this.forgotPassword = !this.forgotPassword
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.site-section{
  margin-top: 6.2rem;
  padding-bottom: 8.15rem;
}
.forgotpassword-link{
  color: #000;
}
.no-register-form{
  display: block;
}
.form-group .form-control, .invalid-feedback{
  direction: rtl;
}

@include media-breakpoint-down(sm) {
  .no-register-form{
    display: none;
  }
  .no-register{
    display: block;
  }
  .site-section {
    margin-top: 2.9rem;
  }
  .section-body .row{
    flex-direction: column-reverse;
  }
  .form-header{
    margin-bottom: 1.5rem;
  }
  .form-title{
    font-size: 1.2rem;
  }
  .form-container-login{
    padding: 0;
  }
  .gong-form .form-group .form-control {
    height: 3.4rem;
    font-size: .8rem;
  }
  .gong-form .form-group {
    margin-bottom: 1.8rem;
  }
  #login .form-container .form-group .btn {
    height: 3.4rem;
    font-size: 1rem;
  }
  .form-body form{
    margin-bottom: 1.8rem;
  }
  .form-container-register{
    display: none;
  }
  #login .form-container .form-group .btn {
    height: inherit;
    font-size: 1rem;
    line-height: 2;
  }
  #login .form-container .form-group .spinner-border {
    vertical-align: middle;
    margin-right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>
